import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "capitalize",
    padding: theme.spacing(1),
    margin: theme.spacing(0, 2, 0, 2),
    fontSize: 15,
    fontWeight: 600,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
}));

const ButtonComponent = ({ children, fullWidth, disabled, ...rest }) => {
  const classes = useStyles();
  return (
    <div>
      <Button
        className={classes.button}
        disabled={disabled || false}
        fullWidth={false || fullWidth}
        {...rest}
      >
        {children}
      </Button>
    </div>
  );
};

export default ButtonComponent;

// const Button = ({ text, classes }) => {
//   return <button className={classes}>{text}</button>;
// };

// export default Button;
