import { makeStyles, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./shopDetails.css";
import MaterialTable from "material-table";

import ImgsViewer from 'react-images-viewer';
const ShopDetails = ({ match, location }) => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
      minHeight: "25vh",
    },
    container: {
      borderRadius: theme.spacing(2),
      border: "1px solid lightgrey",
      padding: theme.spacing(0, 0, 0, 0),
      margin: theme.spacing(0, 0, 10, 0),
    },
    heading: {
      padding: theme.spacing(2, 3, 2, 3),
      borderTopRightRadius: theme.spacing(2),
      borderTopLeftRadius: theme.spacing(2),
      marginBottom: theme.spacing(3),
      color: "white",
    },
  }));

  const classes = useStyles();
  const [shop, setShop] = useState({});

  useEffect(() => {
   // console.log("match",match.params.startDate,match.params.endDate)
    console.log(location.state);
    setShop(location.state);
  }, [location]);

  const generateTalbe = (id,mslsData) => {
    /**
     * categoryImageId: 53
code: "10101900624"
createdAt: "2021-01-04T10:55:16.000Z"
ctn: "48"
entryDate: "04-Jan-2021 15:54:20"
id: 383
name: "Pop Up Duplex Classics 150s - Mazaj Mandala"
packSize: "1"
shopId: 66
size: null
stock: 20
type: "Facial"
updatedAt: "2021-01-04
     */
    return <MaterialTable
    columns={[
      { title: "Name", field: "name" },
      { title: "Pack Size", field: "packSize" },
      { title: "Stock", field: "stock" },
      { title: "Type", field: "type" },
      { title: "CTN", field: "ctn" },
      { title: "Code", field: "code" },
    ]}
    data={mslsData.filter(el => el.categoryImageId === id)}
    
    
    title="MSLS"
  />
  }

  return (
    <React.Fragment>
      <h2>Shop Details</h2>
      <Paper className={classes.paper}>
        <div className="container">
          <div className="double-field">
            <Typography
              className="head"

              style={{ color: "gray", fontWeight: "bold" }}
            >
              ID:
            </Typography>
            <Typography>{shop.id}</Typography>
          </div>

          <div className="double-field">
            <Typography
              className="head"
              style={{ color: "gray", fontWeight: "bold" }}
            >
              Name:
            </Typography>
            <Typography>{shop.name}</Typography>
          </div>
          <div className="double-field">
            <Typography
              className="head"
              style={{ color: "gray", fontWeight: "bold" }}
            >
              Address:
            </Typography>
            <Typography>{shop.address}</Typography>
          </div>
          <div className="double-field">
            <Typography
              style={{ color: "gray", fontWeight: "bold" }}
              className="head"
            >
              Start Date:
            </Typography>
            <Typography>{shop.startDate}</Typography>
          </div>
        </div>
        <div className="container">
          <div className="double-field">
            <Typography
              className="head"
              style={{ color: "gray", fontWeight: "bold" }}
            >
              Store Type:
            </Typography>
            <Typography>{shop.storeType}</Typography>
          </div>
          <div className="double-field">
            <Typography
              className="head"
              style={{ color: "gray", fontWeight: "bold" }}
            >
              Sub Store Type:
            </Typography>
            <Typography>{shop.subStoreType}</Typography>
          </div>

          <div className="double-field">
            <Typography
              className="head"
              style={{ color: "gray", fontWeight: "bold" }}
            >
              Longitude:
            </Typography>
            <Typography>{shop.lng}</Typography>
          </div>
          <div className="double-field">
            <Typography
              className="head"
              style={{ color: "gray", fontWeight: "bold" }}
            >
              Latitude:
            </Typography>
            <Typography>{shop.lat}</Typography>
          </div>
        </div>

        <div className="container">
          <div className="double-field">
            <Typography
              style={{ color: "gray", fontWeight: "bold" }}
              className="head"
            >
              End Date:
            </Typography>
            <Typography>{shop.endDate}</Typography>
          </div>
          <div className="double-field">
            <Typography
              style={{ color: "gray", fontWeight: "bold" }}
              className="head"
            >
              Entry Date:
            </Typography>
            <Typography>{shop.entryDate}</Typography>
          </div>
        </div>
      </Paper>

      <div>
        {shop.categoryImages &&
          shop.categoryImages.map((s) => {
            return (
              <div>
                <div style={{ marginTop: 5 }}>
                  <h2>{s.catName}</h2>
                  <h4>Before Pics</h4>
  
                   <img alt={s.catName} src={s.beforePic1} />
                  <img alt={s.catName} src={s.beforePic2} /> 
                  {generateTalbe(s.id,shop.msls)}

                </div>

                <hr />
                <div style={{ marginTop: 5 }}>
                  <h4>After Pics</h4>
                  <img alt={s.catName} src={s.afterPic1} />
                  <img alt={s.catName} src={s.afterPic2} />
                  
                </div>

                <hr />
                <div style={{ marginTop: 5 }}>
                  <h4>Competitor Pics</h4>
                  <img alt={s.catName} src={s.compPic1} />
                  <img alt={s.catName} src={s.compPic2} />
                  <img alt={s.catName} src={s.compPic3} />
                </div>
              </div>
            );
          })}
      </div>
    </React.Fragment>
  );
};

export default ShopDetails;
