import { Grid, makeStyles, Paper } from "@material-ui/core";
import React, { useState } from "react";
import api from "../../api/api";
import ButtonComponent from "../../components/Button/Button";
import Input from "../../components/Input/Input";

const useStyles = makeStyles((theme) => ({
  div: {
    height: "99vh",
  },
  width: {
    minHeight: "375px",
    width: "25%",
    padding: theme.spacing(10),
  },
  cent: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
    fontWeight: "bold",
  },
}));

const Login = ({ history }) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const onLogin = () => {
    api
      .post("/auth", { email, password })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.user));
        window.location = "/shops";
      })
      .catch((err) => setError("Invalid Email or Password"));
  };

  return (
    <Grid
      container
      style={{ backgroundColor: "grey", minHeight: "100vh" }}
      justify="center"
      alignItems="center"
    >
      <Paper className={classes.width}>
        <Grid container direction="column" alignItems="center">
          {error ? <p className="alert alert-danger">{error}</p> : null}
          <h2>Login</h2>
          <Input
            fullWidth={true}
            variant="outlined"
            id="email"
            name="email"
            label="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.currentTarget.value);
            }}
          />
          <Input
            type="password"
            fullWidth={true}
            variant="outlined"
            id="password"
            name="password"
            label="Password"
            value={password}
            onChange={(e) => {
              setPassword(e.currentTarget.value);
            }}
          />

          <div style={{ width: "14em", marginRight: 18 }}>
            <ButtonComponent
              fullWidth={true}
              color="primary"
              variant="contained"
              onClick={onLogin}
            >
              Login
            </ButtonComponent>
          </div>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Login;
