import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// import "../../node_modules/material-design-icons/iconfont/material-icons.css";
import { navConfig } from "../utils/sidebarData";

import { Link, NavLink } from "react-router-dom";
import { Popover, Paper, Avatar } from "@material-ui/core";
import { FaUsers } from "react-icons/fa";
import { GrHome, GrTemplate } from "react-icons/gr";
import { RiTeamLine } from "react-icons/ri";
import { MdGroupWork } from "react-icons/md";
import { SiWheniwork } from "react-icons/si";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(11) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(11) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  toolbarDropdown: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
  },
  nested: {
    paddingLeft: theme.spacing(6),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  typography: {
    minWidth: 100,
    //minHeight:200,
    padding: theme.spacing(2),
  },
  paddingTB: {
    width: "100%",
    margin: theme.spacing(1, 0, 1, 0),
  },
  Icons: {
    fontSize: 20,
    color: "#000",
  },
  fonts: {
    fontSize: 15,
    color: "#000000",
  },
}));

function MiniDrawer({ children, history }) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openList, setOpenList] = React.useState(false);
  const [expandList, setExpandList] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [token, setToken] = useState();

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const list = [];
    navConfig.forEach((item) => list.push(false));
    setExpandList(list);
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);
  return (
    <div className={classes.root}>
      <Popover
        className={classes.accountPopover}
        id={id}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Paper className={classes.typography}>
          <Typography>Profile</Typography>
          <Divider className={classes.paddingTB} />
          <Typography>Settings</Typography>
          <Divider className={classes.paddingTB} />
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => {
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              window.location = "/";
            }}
          >
            Logout
          </Typography>
        </Paper>
      </Popover>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Merchandiser
          </Typography>

          {/* <Typography style={{ marginLeft: "72%" }} variant="h6" noWrap>
            {user.fName} {user.lName}
          </Typography> */}

          <span style={{ flex: 1 }} />

          {!token ? (
            <div>
              <NavLink to="/login" className="btn btn-primary">
                Login
              </NavLink>
              <NavLink to="/register" className="btn btn-secondary">
                Register
              </NavLink>
            </div>
          ) : null}

          <Avatar
            onClick={handleClick}
            // src={`http://localhost:3001/${user.profileImg}`}
          />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem>
            <ListItemIcon>
              <GrHome className={classes.Icons} />
            </ListItemIcon>
            <ListItemText>
              <NavLink to="/shops" className={classes.fonts}>
                Shops
              </NavLink>
            </ListItemText>
          </ListItem>
          {/* <ListItem>
            <ListItemIcon>
              <SiWheniwork className={classes.Icons} />
            </ListItemIcon>
            <ListItemText> */}
          {/* <NavLink to="/workspaceListing" className={classes.fonts}>
                Workspaces
              </NavLink> */}
          {/* </ListItemText> */}
          {/* </ListItem> */}
          {/* <ListItem>
            <ListItemIcon>
              <RiTeamLine className={classes.Icons} />
            </ListItemIcon>
            <ListItemText>
              <NavLink to="/users" className={classes.fonts}>
                Users
              </NavLink>
            </ListItemText>
          </ListItem> */}
          {/* <ListItem>
            <ListItemIcon>
              <RiTeamLine className={classes.Icons} />
            </ListItemIcon>
            <ListItemText>
              <NavLink to="/teams" className={classes.fonts}>
                Teams
              </NavLink>
            </ListItemText>
          </ListItem> */}
          {/* <ListItem>
            <ListItemIcon>
              <GrTemplate className={classes.Icons} />
            </ListItemIcon>
            <ListItemText>
              <NavLink to="/templateListing" className={classes.fonts}>
                Templates
              </NavLink>
            </ListItemText>
          </ListItem> */}
          {/* <ListItem>
            <ListItemIcon>
              <FaUsers className={classes.Icons} />
            </ListItemIcon>
            <ListItemText>
              <NavLink to="/client" className={classes.fonts}>
                Clients
              </NavLink>
            </ListItemText>
          </ListItem> */}
        </List>
        <Divider />
        {/* <List>
          {["All mail", "Trash", "Spam"].map((text, index) => (
            <ListItem onClick={() => console.log(text)} button key={text}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
      </Drawer>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}

export default MiniDrawer;
