import "./App.css";
import TopLayer from "./screens/TopLayer/TopLayer";
import { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import Login from "./screens/Login/Login";
import Shops from "./screens/Shop/Shops";
import ShopDetails from "./screens/ShopDetails/ShopDetails";

const App = () => {
  const [token, setToken] = useState();

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);

  return (
    <div>
      {token ? (
        <TopLayer>
          <Route path="/shops" component={Shops} />
          <Route
            path="/shop-details/:id/:startDate/:endDate"
            component={ShopDetails}
          />
        </TopLayer>
      ) : null}
      <Route path="/" exact component={Login} />
    </div>
  );
};

export default App;
