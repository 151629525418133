import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0091ea",
      light: "#F0F2F0",
    },
    secondary: {
      main: "#000C40",
      light: "##F0F2F0",
    },
  },
  spacing: 5,
  shape: {
    borderRadius: 5,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

export default theme;
