export const navConfig = [
    {
        title: 'Dashboard',
        route: '/dashboard',
        icon: 'dashboard'
    },
    {
        title: 'City',
        route: '/cities',
        icon: 'location_city'
    },
    {
        title: 'Department',
        route: '/departments',
        icon: 'domain'
    },
    {
        title: 'Users',
        icon: 'person',
        // route: '/users',
        children: [
            {
                title: 'Users List',
                route: '/users',
                icon: 'person'
            },
            {
                title: 'Add User',
                route: '/user',
                icon: 'person_add'
            },
        ]
    },
    {
        title: 'Projects',
        // route: '/projects',
        icon: 'folder',
        children: [
            {
                title: 'Projects List',
                route: '/projects',
                icon: 'folder'
            },
            {
                title: 'Add Project',
                route: '/project',
                icon: 'create_new_folder'
            },
        ]
    },
    {
        title: 'Project Library',
        route: '/libraries',
        icon: 'library_books'
    },
]