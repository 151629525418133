import MaterialTable from "material-table";
import React, { useState, useEffect } from "react";
import { DatePicker,MuiPickersUtilsProvider  } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'
import api from "../../api/api";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {Grid,Button} from '@material-ui/core';


const Shops = ({ history }) => {
  

  const [shops, setShops] = useState([]);
  const [data,setData]= useState({startingDate:new Date(),endingDate:new Date()})

  useEffect(() => {
    
   
  }, []);

  const onChangeDate=(e,id)=>{
    let temp={...data}
    console.log(moment(e).format("DD/MM/YYYY"))
    // temp[id]=moment(e).format("DD/MM/YYYY")
    temp[id]=e
    console.log(temp)
    setData(temp)
}
const handleSubmit = ()=>{
  api
  .get(`/shops/${moment(data.startingDate).format("DD-MMM-YYYY")}/${moment(data.endingDate).format("DD-MMM-YYYY")}`)
  .then((res) => {
    console.log(res.data, "shops");
    setShops(res.data);
  })
  .catch((err) => console.log(err));
}

  return (
    <div>
      <Grid container>
      <Grid container xs={3} alignItems="center">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        autoOk
                        fullWidth
                        size="small"

                        label="From Date"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        placeholder="Starting Date"
                        value={data.startingDate}
                        onChange={(date)=>onChangeDate(date,'startingDate')}
                        animateYearScrolling
                        id="startingDate"
                        margin="normal"
                    />
                </MuiPickersUtilsProvider>
            </Grid>
                <Grid container xs={3} style={{marginLeft:"1em"}} alignItems="center">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        autoOk
                        fullWidth
                        size="small"

                        label="To Date"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        placeholder="Ending Date"
                        value={data.endingDate}
                        onChange={(date)=>onChangeDate(date,'endingDate')}
                        animateYearScrolling
                        id="endingDate"
                        margin="normal"
                    />
                </MuiPickersUtilsProvider>
  
  

      </Grid>

      <Grid container xs={3} style={{marginLeft:"1em"}} alignItems="center">
      <Button color="primary" variant="contained" size="small" style={{height:"34px"}} onClick={handleSubmit}
  >Submit</Button>
      </Grid>
      
      </Grid>
    <MaterialTable
        columns={[
          { title: "ID", field: "id" },
          { title: "Shop Name", field: "name" },
          { title: "Address", field: "address" },
          { title: "Entry Date", field: "entryDate" },
          { title: "Start Date", field: "startDate" },
          { title: "Store Type", field: "storeType" },
          { title: "Sub Store Type", field: "subStoreType" },
          { title: "Latitude", field: "lat" },
          { title: "Longitude", field: "lng" },
          { title: "Remarks", field: "remarks" },

          { title: "User", field: "user.email" },
        ]}
        data={shops}
        actions={[
          {
            icon: VisibilityIcon,
            tooltip: "View Record",
            onClick: (event, rowData) =>
              history.push({
                pathname: `shop-details/${rowData.id}/${moment(data.startingDate).format("DD-MMM-YYYY")}/${moment(data.endingDate).format("DD-MMM-YYYY")}`,
                state: rowData,
              }),
          },
        ]}
        title="Shops"
      />
    </div>
  );
};

export default Shops;
